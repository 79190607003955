import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, RankQuery } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";

function useTextState(value: string): [string, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback((event) => setText(event.target.value), [
    setText,
  ]);
  return [text, updateText];
}

function useIntState(value: number): [number, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback(
    (event) => setText(parseInt(event.target.value, 10)),
    [setText]
  );
  return [text, updateText];
}

export default function Rank(props: {
  query: RankQuery;
  updateQuery: (query: Query) => void;
}) {
  const [text, updateText] = useTextState(props.query.q);
  const [startYear, updateStartYear] = useIntState(-4000)
  const [endYear, updateEndYear] = useIntState(2022)

  const doSearch = React.useCallback(() => {
    props.updateQuery({
      action: "rank",
      q: text,
      startYear,
      endYear
    });
  }, [text, props.updateQuery]);

  const search = useApi(
    () => ({
      type: "RANK",
      labels: props.query.q.split('\n'),
      startYear: props.query.startYear,
      endYear: props.query.endYear
    }),
    [props.query]
  );

  return (
    <Container>

      <Form>
        <Form.Group controlId="text">
          <Form.Label>Items</Form.Label>
          <Form.Control value={text} onChange={updateText} type="text" as="textarea" />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="from">
              <Form.Label>From</Form.Label>
              <Form.Control value={startYear} onChange={updateStartYear} type="number" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="to">
              <Form.Label>To</Form.Label>
              <Form.Control value={endYear} onChange={updateEndYear} type="number" />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" onClick={doSearch} >Search</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
          <Table striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {search.result.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.score}
                  </td>

                </tr>
              ))}
            </tbody>

          </Table>)}
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container>
  );
}
