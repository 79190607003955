import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, SearchBase, ExportQuery } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import XLSX from "xlsx"
import { prepareQuery } from "./SearchForm";

export default function Export(props: {
  query: ExportQuery;
  updateQuery: (query: Query) => void;
}) {
  const book = React.useRef(XLSX.utils.book_new())

  const [searchResults, setSearchResults] = React.useState<any>(null)
  const [inspectResults, setInspectResults] = React.useState<any[]>([])
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)

  const onDownload = React.useCallback(() => {
    XLSX.writeFile(book.current, "results1.xlsx")
  }, [book])

  const search = useApi(
    () => (currentIndex === null || currentIndex === searchResults.length ? {
      type: "SEARCH",
      ...prepareQuery(props.query),
    } : {
        type: "INSPECT",
        link: "",
        ...prepareQuery(props.query),
        target: searchResults[currentIndex].entity
      }),
    [props.query, currentIndex, searchResults]
  );

  const lastRequest = React.useRef(undefined)

  React.useEffect(() => {
    if (lastRequest.current === search.result) {
      return
    }
    lastRequest.current = search.result
    if (search.result !== undefined) {
      if (currentIndex === null) {
        setCurrentIndex(0)
        setSearchResults(search.result);
        const sheet = XLSX.utils.aoa_to_sheet([
          ["Name", "Score"],
          ...search.result.map((row: any) => [row.name, row.score])
        ])
        XLSX.utils.book_append_sheet(book.current, sheet, "Results")
      } else if (currentIndex >= searchResults!.length) {
        XLSX.writeFile(book.current, "results.xlsx")
      } else if (currentIndex === inspectResults.length) {
        setCurrentIndex(currentIndex + 1)
        setInspectResults([...inspectResults, search.result])
        XLSX.utils.book_append_sheet(book.current,
          XLSX.utils.aoa_to_sheet([
            ["Source", "Link", "Score"],
            ...search.result.map((row: any) => [row.source, row.link, row.score])
          ])
          , (currentIndex + "." + (searchResults[currentIndex].name.replace(/[\]\[\*\?\/\\]/g, '@') || "")).substring(0, 31))
      }
    }
  }, [search.result, currentIndex, setCurrentIndex, inspectResults, setInspectResults])


  return (
    <Container>
      {searchResults && currentIndex === searchResults.length ? (
        <Button onClick={onDownload}>Download</Button>
      ) : (
          <div>
            {searchResults !== null && <><Alert variant="success">
              <FontAwesomeIcon icon={faCheck} /> Search
          </Alert>
              {searchResults.slice(0, currentIndex).map((row: any, index: number) =>
                <Alert variant="success">
                  <FontAwesomeIcon icon={faCheck} key={index} />{row.name}
                </Alert>
              )}
            </>
            }
            {search.statuses.map((status, index) => (
              <Alert variant="success" key={index}>
                <FontAwesomeIcon icon={faCheck} /> {status}
              </Alert>
            ))}
            {search.error === undefined && (
              <Alert variant="primary">
                <FontAwesomeIcon icon={faSpinner} spin /> Working
              </Alert>
            )}
          </div>)}

      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container>
  );
}
