import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import gaussian from 'gaussian'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, CrossrefAuthorQuery, Kind } from "./Query";
import { Container, Form, Button, Alert, Table, Card } from "react-bootstrap";
import { LineChart, XAxis, YAxis, Line } from "recharts"
import { sum } from "lodash-es"

function useTextState(value: string): [string, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback((event) => setText(event.target.value), [
    setText,
  ]);
  return [text, updateText];
}

function useBoolState(value: boolean): [boolean, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback(
    (event) => setText(event.target.checked),
    [setText]
  );
  return [text, updateText];
}

export default function CrossrefAuthorPage(props: {
  query: CrossrefAuthorQuery;
  updateQuery: (query: Query) => void;
}) {
  const [title, updateTitle] = useTextState(props.query.title);

  const doWikipediaPage = React.useCallback(() => {
    props.updateQuery({
      action: "crossref-author",
      title,
    });
  }, [props.updateQuery, title]);



  const search = useApi(
    () => ({
      type: "CROSSREFAUTHOR",
      name: props.query.title
    }),
    [props.query]
  );

  const yearsData = (search.result?.years || [])

  return (
    <Container>
      <Form>
        <Form.Group controlId="kind">
          <Form.Label>Author</Form.Label>
          <Form.Control value={title} onChange={updateTitle} type="text" />
        </Form.Group>
        <Button variant="primary" onClick={doWikipediaPage} >Lookup</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
        search.result.map((result: any) => (
          <>
            <h1>{result.name}: {result.score} {result.wikidata}</h1>
            <ul>
              {result.affiliations.map((x: string) => <li key={x}>{x}</li>)}
            </ul>
            <LineChart width={500} height={300} data={result.data.cite_paper_years.map(([year, amount]:[number, number]) => ({year, amount}))}>
              <XAxis dataKey="year"/>
              <YAxis/>
              <Line type="monotone" dataKey="amount" stroke="#8884d8"/>
            </LineChart>

            <LineChart width={500} height={300} data={result.data.citing_paper_years.map(([year, amount]:[number, number]) => ({year, amount}))}>
              <XAxis dataKey="year"/>
              <YAxis/>
              <Line type="monotone" dataKey="amount" stroke="#8884d8"/>
            </LineChart>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Citations</th>
                </tr>
              </thead>
              <tbody>
                {result.data.cited_authors.map((item:any) => (
                  <tr>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.count}
                    </td>
                    </tr>
                ))}
              </tbody>
              </Table>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Citations</th>
                </tr>
              </thead>
              <tbody>
                {result.data.citing_authors.map((item:any) => (
                  <tr>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.count}
                    </td>
                    </tr>
                ))}
              </tbody>
              </Table>
 

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>DOI</th>
                  <th>Container</th>
                  <th>Citations</th>
                </tr>
              </thead>
              <tbody>
                {result.papers.map((link: any, index: number) => (
                  <>
                    <tr>
                      <td>{link.title}</td>
                      <td>{link.doi}</td>
                      <td>{link.container}</td>
                      <td>{link.citations.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>{link.subtitle}</td>
                      <td>{link.abstract}</td>
                      <td>{link.subject.join(';')}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>{link.abstract}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            <pre>
              {JSON.stringify(result.data, null, 4)}
            </pre>
          </>)))}
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container >
  );
}
