import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import gaussian from 'gaussian'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, WikipediaPageQuery, Kind } from "./Query";
import { Container, Form, Button, Alert, Table, Card } from "react-bootstrap";
import { LineChart, XAxis, YAxis, Line } from "recharts"
import { sum } from "lodash-es"

function useTextState(value: string): [string, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback((event) => setText(event.target.value), [
    setText,
  ]);
  return [text, updateText];
}

function useBoolState(value: boolean): [boolean, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback(
    (event) => setText(event.target.checked),
    [setText]
  );
  return [text, updateText];
}

export default function WikipediaPage(props: {
  query: WikipediaPageQuery;
  updateQuery: (query: Query) => void;
}) {
  console.log("GOT", props.query)
  const [title, updateTitle] = useTextState(props.query.title);

  const doWikipediaPage = React.useCallback(() => {
    props.updateQuery({
      action: "wikipedia-page",
      title,
    });
  }, [props.updateQuery, title]);



  const search = useApi(
    () => ({
      type: "WIKIPEDIAPAGE",
      title: props.query.title
    }),
    [props.query]
  );

  const yearsData = (search.result?.years || [])

  return (
    <Container>
      <Form>
        <Form.Group controlId="kind">
          <Form.Label>Page</Form.Label>
          <Form.Control value={title} onChange={updateTitle} type="text" />
        </Form.Group>
        <Button variant="primary" onClick={doWikipediaPage} >Lookup</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
          <>
            <h1>{search.result.title}</h1>
            {
              Object.entries(search.result.viewsByMonth).map(([key, value]: any) => (
                <>
                <h2>{key}</h2>
            <LineChart width={500} height={300} data={
              value.map((views: number, index: number) => ({
                index,
                views,
                acceptedViews: search.result.views
              }))
            }>
              <Line dot={false} type="monotone" dataKey="views" stroke="#8884d8" />
              <Line dot={false} dataKey="acceptedViews" stroke="#88d884" />
              <XAxis dataKey="index" />
              <YAxis />
            </LineChart>
            </>
              ))
            }
           <Table>
              <tbody>
                <tr><th>Views</th><td>{search.result.views.toLocaleString()}</td></tr>
                <tr><th>Predicted Views</th><td>{search.result.predictedViews.toLocaleString()}</td></tr>
                <tr><th>Effective Views</th><td>{search.result.effectiveViews.toLocaleString()}</td></tr>
              </tbody>
            </Table>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Link Count</th>
                  <th>Title Count</th>
                </tr>
              </thead>
              <tbody>
                {search.result.links.map((link: any, index: number) => (
                  <tr>
                    <td>{link.title}</td>
                    <td>{link.linkCount}</td>
                    <td>{link.titleCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card>
              <Card.Body>
                <Card.Text>Wikipedia Text</Card.Text>
                <Card.Text>{search.result.text}</Card.Text>
              </Card.Body>
            </Card>
          </>)
      }
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container >
  );
}
