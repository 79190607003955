import {
    faCheck,
    faSpinner,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, SearchBase } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import SiteButton from "./SiteButton"
import SearchForm, { BASE, prepareQuery } from "./SearchForm"
import { sortBy } from "lodash-es"
import { stringify } from "querystring";
import { formatDistanceStrict as distanceInWords } from "date-fns"

export default function Disciplines(props: {
}) {
    const [config, updateConfig] = React.useState<any>(null);
    const [pending, updatePending] = React.useState<any>(null);
    const [baseline, updateBaseline] = React.useState<Date>(new Date())
    const [log, updateLog] = React.useState<{ text: string, time: Date }[]>([])

    React.useEffect(() => {
        fetch("/config").then(response => response.json()).then(config => {
            const options = []
            for (const discipline of config.disciplines) {
                options.push({
                    ...BASE,
                    action: "search",
                    crossref: true,
                    q: [discipline.name, ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.name)].map(x => x.toLowerCase()).join(","),
                    wikidataIds: [discipline.wikidata_ids.join(","), ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.wikidata_ids.join(","))].join(","),
                })
            }
            updatePending(options[0])
            updateConfig(options.slice(1))
            updateBaseline(new Date())
            updateLog([{
                text: "Starting",
                time: new Date()
            }])
        })
    }, [updateConfig, updatePending])
    const search = useApi(
        () => ({
            type: "SEARCH",
            ...prepareQuery(pending || BASE)
        }),
        [pending]
    );
    React.useEffect(() => {
        if (search.result !== undefined && pending) {
            updatePending(config[0])
            updateConfig(config.slice(1))
            updateLog([...log, {
                text: pending.q,
                time: new Date()
            }])
        }
    }, [search, pending])
    return (
        <Container>
            <pre>
                {JSON.stringify(pending, null, 4)}
                {JSON.stringify(search, null, 4)}
            </pre>
            <ol>
                {log.map((item, index) => <li key={index}>
                    {distanceInWords(baseline, item.time)} {item.text}
                </li>)}
            </ol>
        </Container>
    );
}
