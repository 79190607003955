import { EventEmitter } from "events";
import * as React from "react";
import { useGoogleLogin } from "react-use-googlelogin";
import { GoogleUser } from "react-use-googlelogin/dist/types";
import { Kind } from "./Query";
type SearchParameters = {
  query: string;
  startYear: number;
  endYear: number;
  birthYear: number | null;
  kind: Kind;
  wikipedia: boolean;
  crossref: boolean;
  weights: {
    indirect: number,
    direct: number,
    leader: number
  },
  everyYear: boolean,
  limit: number,
  journals: string[],
  name: string
};

export type Api =
  | ({
    type: "SEARCH";
  } & SearchParameters)
  | ({
    type: "INSPECT";
    target: number,
    link: string
  } & SearchParameters)
 |{
    type: "TIMELINE"
  } & SearchParameters
  | {
    type: "WIKIPEDIAPAGE",
    title: string
  } | {
    type: "RANK",
    labels: string[],
    startYear: number,
    endYear: number
  } | {
    type: "CROSSREFAUTHOR",
    name: string
  } | {
    type: "CANCEL"
  } | {
    type: "EXPAND",
  }




let nextRequestId = 0;

export type AppContextInfo = {
  isSignedIn: boolean;
  send?: (api: Api & { id: string }) => void;
  unlisten: (key: string) => void,
  listen: (key: string, fn: (value: any) => void) => void;
};
const ApiReactContext = React.createContext((null as any) as AppContextInfo);

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "wss://rankinganalytics.com/api/"
    : "ws://localhost:4001/api/";

export default function AppContext(props: { children: React.ReactNode }) {
  const events = React.useMemo(() => new EventEmitter(), []);

  const [credential, setCredential] = React.useState<string|null>(null)

  React.useEffect(() => {
    (window as any).google.accounts.id.initialize({
      client_id: process.env.GOOGLE_OAUTH_CLIENT_ID,
      callback: (response: any) => {
        setCredential(response.credential)
      }
    });
    (window as any).google.accounts.id.prompt()
  }, [])

/*  const { isSignedIn, signOut, signIn, googleUser } = useGoogleLogin({
    clientId: process.env.GOOGLE_OAUTH_CLIENT_ID!,
    autoSignIn: 'auto'
  });*/

  const [socket, setSocket] = React.useState<WebSocket | null>(null);
  const [socketState, setSocketState] = React.useState<number | null>(null);
  const [counter, setCounter] = React.useState(0);

  const isSignedIn = credential !== null

  React.useEffect(() => {
    if (credential) {
      const socket = new WebSocket(BASE_URL + credential);
      socket.addEventListener("message", (message) => {
        const parsed = JSON.parse(message.data);
        events.emit(parsed.id, parsed);
      });
      const callback = () => {
        setSocketState(socket.readyState);
      };
      const onClose = () => {
        setSocketState(socket.readyState);
        setTimeout(() => {
          setCounter(counter + 1);
        }, 1000);
      };
      callback();
      socket.addEventListener("open", callback);
      socket.addEventListener("close", onClose);
      setSocket(socket);
    } else {
      setSocket(null);
      setSocketState(null);
    }
  }, [credential, counter]);

  const send = React.useMemo(() => {
    if (socket && socketState == WebSocket.OPEN) {
      return (message: Api) => {
        socket.send(JSON.stringify(message));
      };
    }
  }, [socket, socketState]);

  const listen = React.useCallback(
    (key, callback) => {
      events.addListener(key, (value) => callback(value));
    },
    [events]
  );

  const unlisten = React.useCallback(
    (key) => {
      events.removeAllListeners(key)
    },
    [events]
  );

  return (
    <ApiReactContext.Provider
      value={{
        unlisten,
        isSignedIn,
        send,
        listen,
      }}
    >
      {props.children}
    </ApiReactContext.Provider>
  );
}

export function useAppContext(): AppContextInfo {
  return React.useContext(ApiReactContext);
}

type RequestState = {
  statuses: string[];
  result: any;
  error?: string;
};

function requestReducer(state: RequestState, action: any): RequestState {
  switch (action.type) {
    case "CLEAR": {
      return {
        statuses: [],
        result: undefined,
      };
    }
    case "STATUS":
      return {
        ...state,
        statuses: [...state.statuses, action.text],
      };
    case "RESULT":
      return {
        ...state,
        result: action.value,
      };
    case "ERROR":
      return {
        ...state,
        error: action.text,
      };
    default:
      throw new Error("Unreachable")
  }
}

export function useApi(
  api: () => Api,
  dependencies: React.DependencyList
): RequestState & { live: boolean } {
  const appContext = useAppContext();
  const [state, reduce] = React.useReducer(requestReducer, {
    statuses: [],
    result: undefined,
  } as RequestState);
  React.useEffect(() => {
    const id = `${nextRequestId++}`;

    reduce({ type: "CLEAR" });

    if (appContext.send) {
      appContext.send({
        ...api(),
        id,
      });
    }

    appContext.listen(id, (got) => {
      reduce(got);
    });

    return () => {
      if (appContext.send) {
        appContext.send({
          type: "CANCEL",
          id
        })
      }
      appContext.unlisten(id)
    }
  }, [...dependencies, appContext.send, appContext.listen]);

  return {
    ...state,
    live: appContext.send !== undefined,
  };
}
