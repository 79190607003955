import {
    faCheck,
    faSpinner,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, SearchBase } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import SiteButton from "./SiteButton"
import SearchForm, { BASE, prepareQuery } from "./SearchForm"
import { sortBy } from "lodash-es"

export default function Disciplines(props: {
    updateQuery: (query: Query) => void;
}) {
    const [config, updateConfig] = React.useState<any>(null);

    React.useEffect(() => {
        fetch("/config").then(response => response.json()).then(updateConfig)
    }, [updateConfig])

    const overallDisciplines = []

    if (config) {
        const ok_parents = []
        for (const discipline of config.disciplines) {
            if (discipline.active && !discipline.black_hole) {
                overallDisciplines.push(discipline)
                ok_parents.push(discipline.id)
            }
        }

        for (const subdiscipline of config.subdisciplines) {
            console.log("OK", subdiscipline)
            if (subdiscipline.active && ok_parents.indexOf(subdiscipline.parent) != -1) {
                overallDisciplines.push(subdiscipline)
            }
        }
    }

    return (
        <Container>
            {config && <h1>Overall
                    <SiteButton query={{
                    ...BASE,
                    crossref: true,
                    action: "search",
                    q: overallDisciplines.filter(x => !x.black_hole).map((child: any) => child.name.toLowerCase()).join(","),
                    wikidataIds: overallDisciplines.filter(x => !x.black_hole).map((child: any) => child.wikidata_ids.join(",")).join(","),
                }} >Search</SiteButton>
                    <SiteButton query={{
                    ...BASE,
                    limit: 10,
                    action: "timeline",
                    q: overallDisciplines.filter(x => !x.black_hole).map((child: any) => child.name.toLowerCase()).join(","),
                    wikidataIds: overallDisciplines.filter(x => !x.black_hole).map((child: any) => child.wikidata_ids.join(",")).join(","),
                }} >Timeline</SiteButton>
            </h1>}

            {config && sortBy(config.disciplines, 'name').map((discipline: any) => <>
                <h1>{discipline.name}
                    <SiteButton query={{
                        ...BASE,
                        crossref: true,
                        action: "search",
                        q: [discipline.name, ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.name)].map(x => x.toLowerCase()).join(","),
                        wikidataIds: [discipline.wikidata_ids.join(","), ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.wikidata_ids.join(","))].join(","),
                    }

                    } >Search</SiteButton>
                    <SiteButton query={{
                        ...BASE,
                        limit: 10,
                        action: "timeline",
                        q: [discipline.name, ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.name)].map(x => x.toLowerCase()).join(","),
                        wikidataIds: [discipline.wikidata_ids.join(","), ...config.subdisciplines.filter((x: any) => x.parent == discipline.id).map((child: any) => child.wikidata_ids.join(","))].join(","),
                    }

                    } >Timeline</SiteButton>
                </h1>
            </>)}
            {JSON.stringify(config)}
        </Container>
    );
}
