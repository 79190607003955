import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, InspectQuery, Kind, SearchBase } from "./Query";
import { Container, Form, Button, Alert, Table } from "react-bootstrap";
import SiteButton from "./SiteButton";
import SearchForm, { prepareQuery } from "./SearchForm";

function useTextState(value: string): [string, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback((event) => setText(event.target.value), [
    setText,
  ]);
  return [text, updateText];
}

function useBoolState(value: boolean): [boolean, (event: any) => void] {
  const [text, setText] = React.useState(value);
  const updateText = React.useCallback(
    (event) => setText(event.target.checked),
    [setText]
  );
  return [text, updateText];
}

export default function Inspect(props: {
  query: InspectQuery;
  updateQuery: (query: Query) => void;
}) {
  console.log("Q", props.query)
  const [base, updateBase] = React.useState(props.query as SearchBase)

  const doInspect = React.useCallback(() => {
    props.updateQuery({
      ...base,
      action: "inspect",
      target: props.query.target,
      link: ""
    });
  }, [base, props.updateQuery]);

  const setLink = React.useCallback((link: string) => {
    props.updateQuery({
      ...props.query,
      link
    })
  }, [props.query, props.updateQuery])

  const search = useApi(
    () => ({
      type: "INSPECT",
      target: props.query.target,
      link: props.query.link,
      ...prepareQuery(base)

    }),
    [props.query]
  );

  let total = 0

  return (
    <Container>
      <Form>
        <SearchForm search={base} updateSearch={updateBase} />
        <Button variant="primary" onClick={doInspect} >Inspect</Button>
        <SiteButton query={{
          ...props.query,
          link: ""
        }}>
          Reset Link
        </SiteButton>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
          <Table striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Source</th>
                <th>Category</th>
                <th>Link</th>
                <th>Link Weight</th>
                <th>Views</th>
                <th>Effective Views</th>
                <th>Relevance</th>
                <th>Time Decay Factor</th>
                <th>Time Factor</th>
                <th>Score</th>
                <th>Cum. Score</th>
              </tr>
            </thead>
            <tbody>
              {search.result.map((item: any, index: number) => {
                total += item.score;
                return (
                  <tr key={index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {item.source}
                      {item.source.startsWith("Wikipedia: ") &&
                        <SiteButton size="sm" query={{
                          action: "wikipedia-page",
                          title: item.source.substring("Wikipedia: ".length)
                        }}>

                          <FontAwesomeIcon icon={faInfoCircle} />
                        </SiteButton>
                      }
                      {item.source.startsWith("CrossrefAuthor: ") &&
                        <SiteButton size="sm" query={{
                          action: "crossref-author",
                          title: item.source.substring("CrossrefAuthor: ".length, item.source.indexOf('@')).trim()
                        }}>

                          <FontAwesomeIcon icon={faInfoCircle} />
                        </SiteButton>
                      }
                    </td>
                    <td>
                      {item.category}
                    </td>
                    <td>
                      {item.link}
                      {props.query.link === "" && <SiteButton size="sm" query={{
                        ...props.query,
                        link: item.link
                      }}>

                        <FontAwesomeIcon icon={faInfoCircle} />
                      </SiteButton>}
                    </td>
                    <td>
                      {item.linkWeight.toLocaleString()}
                    </td>
                    <td>
                      {item.views.toLocaleString()}
                    </td>
                    <td>
                      {item.effectiveViews.toLocaleString()}
                    </td>
                    <td>
                      {item.relevance.toFixed(2)}
                      <pre>
                        {JSON.stringify(item.explanation, null, 4)}
                      </pre>
                    </td>
                    <td>
                      {item.timeDecayFactor.toFixed(2)}
                    </td>
                    <td>
                      {item.timeFactor.toFixed(2)}
                    </td>
                    <td>
                      {item.score && item.score.toLocaleString()}
                    </td>
                    <td>{total.toLocaleString()}</td>
                  </tr>
                )
              })}
            </tbody>

          </Table>)
      }
      { search.error && <Alert variant="error">{search.error}</Alert>}
    </Container >
  );
}
