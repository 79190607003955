import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, SearchBase } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import SiteButton from "./SiteButton"
import SearchForm, { prepareQuery } from "./SearchForm"

export default function Search(props: {
  query: SearchQuery;
  updateQuery: (query: Query) => void;
}) {
  const [base, updateBase] = React.useState(props.query as SearchBase)

  const doSearch = React.useCallback(() => {
    props.updateQuery({
      ...base,
      action: "search",
    });
  }, [base, props.updateQuery]);

  const doExport = React.useCallback(() => {
    props.updateQuery({
      ...base,
      action: "export"
    })
  }, [base, props.updateQuery])

  const search = useApi(
    () => ({
      type: "SEARCH",
      ...prepareQuery(base)
    }),
    [props.query]
  );
  React.useEffect(() => {
    if (window.opener && search.result !== undefined) {
      window.opener.postMessage("!", '*')

      const onMessage = (message: MessageEvent) => {
        if (message.data === '?') {
          window.opener.postMessage(JSON.stringify({ schools: search.result }), '*')
        }
      }
      window.addEventListener("message", onMessage)
      return () => {
        window.removeEventListener("message", onMessage)
      }
    }
  }, [search])

  return (
    <Container>

      <Form>
        <SearchForm search={base} updateSearch={updateBase} />
        <Button variant="primary" onClick={doSearch} >Search</Button>
        <Button variant="primary" onClick={doExport} >Export</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
          <Table striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Penalty</th>
                <th>Score</th>

              </tr>
            </thead>
            <tbody>
              {search.result.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.penalty_detail.length > 0 && <ul>
                      {item.penalty_detail.map((detail: any) => <li>{detail[0]}: {detail[1]}</li>)}
                      </ul>
                      }
                      </td>
                  <td>
                    {item.score && item.score.toLocaleString()}
                  </td>
                  <td>
                    <SiteButton query={{
                      ...props.query,
                      action: "inspect",
                      link: "",
                      target: item.entity
                    }}>Inspect</SiteButton>
                  </td>
                </tr>
              ))}
            </tbody>

          </Table>)}
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container>
  );
}
