import * as React from "react"
import { ButtonProps, Button } from "react-bootstrap";
import { Query } from "./Query"
import queryString from "querystring"
export default function SiteButton(props: ButtonProps & { query: Query }) {
    const { query, ...buttonProps } = props;
    return <Button {...buttonProps} href={
        '#' + queryString.stringify({
            ...query,
            target: JSON.stringify((query as any).target),
        } as any)
    } />
}