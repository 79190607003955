import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, ExpandQuery, Kind } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import SiteButton from "./SiteButton"

export default function Expand(props: {
  query: ExpandQuery;
  updateQuery: (query: Query) => void;
}) {
  const [base, updateBase] = React.useState(props.query)

  const doExpand = React.useCallback(() => {
    props.updateQuery({
      ...base,
      action: "expand",
    });
  }, [base, props.updateQuery]);

  const search = useApi(
    () => ({
      type: "EXPAND",
      wikidataIds: props.query.wikidataIds.split(","),
    }),
    [props.query]
  );

  const updateWikidataIds = React.useCallback((event) => updateBase({
    ...base,
    wikidataIds: event.target.value
  }), [base, updateBase])



  return (
    <Container>

      <Form>
        <Form.Group controlId="text">
          <Form.Label>Related WikidataIds</Form.Label>
          <Form.Control value={base.wikidataIds} onChange={updateWikidataIds} type="text" />
        </Form.Group>
        <Button variant="primary" onClick={doExpand} >Expand</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : (
          <Table striped bordered>
            <thead>
            </thead>
            <tbody>
              {search.result.keywords.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    {item.keyword}
                  </td>
                  <td>
                    {item.depth}
                  </td>
                  <td>
                    {item.via}
                  </td>
                </tr>
              ))}
            </tbody>

          </Table>)}
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container>
  );
}
