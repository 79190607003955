import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppContext from "./AppContext";
import "./index.css"
import "bootswatch/dist/lux/bootstrap.css"
const root = document.createElement("div");
document.body.appendChild(root);

ReactDOM.render(
  <AppContext>
    <App />
  </AppContext>,
  root
);
