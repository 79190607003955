import { SearchBase } from "./Query"
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import React from "react";
import nl from "date-fns/esm/locale/nl/index.js";

export function prepareQuery(query: SearchBase) {
    return {
        query: query.q,
        startYear: query.startYear,
        endYear: query.endYear,
        birthYear: query.birthYear > -4000 ? query.birthYear: null,
        kind: query.kind,
        wikipedia: query.wikipedia,
        crossref: query.crossref,
        weights: {
            direct: 1.0,
            indirect: 1.0,
            leader: query.leaderPenalty ? -1.0 : 0.0
        },
        wikidataIds: query.wikidataIds.split(","),
        filterWikidataIds: query.filterWikidataIds.split(","),
        journals: query.journals.split(","),
        limit: query.limit,
        name: query.name,
        everyYear: query.everyYear
    }
}

export default function SearchForm(props: {
    search: SearchBase
    updateSearch: (base: SearchBase) => void
}) {



    const setKind = React.useCallback((event) => props.updateSearch({
        ...props.search,
        kind: event.target.value
    }), [props.search, props.updateSearch])

    const updateText = React.useCallback((event) => props.updateSearch({
        ...props.search,
        q: event.target.value
    }), [props.search, props.updateSearch])

    const updateName = React.useCallback((event) => props.updateSearch({
        ...props.search,
        name: event.target.value
    }), [props.search, props.updateSearch])

    const updateWikidataIds = React.useCallback((event) => props.updateSearch({
        ...props.search,
        wikidataIds: event.target.value
    }), [props.search, props.updateSearch])

    const updateFilterWikidataIds = React.useCallback((event) => props.updateSearch({
        ...props.search,
        filterWikidataIds: event.target.value
    }), [props.search, props.updateSearch])

    const updateJournals = React.useCallback((event) => props.updateSearch({
        ...props.search,
        journals: event.target.value
    }), [props.search, props.updateSearch])

    const updateBirthYear = React.useCallback((event) => props.updateSearch({
        ...props.search,
        birthYear: parseInt(event.target.value, 10)
    }), [props.search, props.updateSearch])



    const updateStartYear = React.useCallback((event) => props.updateSearch({
        ...props.search,
        startYear: parseInt(event.target.value, 10)
    }), [props.search, props.updateSearch])

    const updateEndYear = React.useCallback((event) => props.updateSearch({
        ...props.search,
        endYear: parseInt(event.target.value)
    }), [props.search, props.updateSearch])

    const updateLimit = React.useCallback((event) => props.updateSearch({
        ...props.search,
        limit: parseInt(event.target.value, 10)
    }), [props.search, props.updateSearch])

    const updateLeaderPenalty = React.useCallback((event) => props.updateSearch({
        ...props.search,
        leaderPenalty: event.target.checked
    }), [props.search, props.updateSearch])

    const updateEveryYear = React.useCallback((event) => props.updateSearch({
        ...props.search,
        everyYear: event.target.checked
    }), [props.search, props.updateSearch])


    const updateWikipedia = React.useCallback((event) => props.updateSearch({
        ...props.search,
        wikipedia: event.target.checked
    }), [props.search, props.updateSearch])

    const updateCrossref = React.useCallback((event) => props.updateSearch({
        ...props.search,
        crossref: event.target.checked
    }), [props.search, props.updateSearch])

    return <>
        <Form.Group controlId="kind">
            <Form.Label>Kind</Form.Label>
            <Form.Control as="select" value={props.search.kind} onChange={setKind}>
                <option value="PERSON">Person</option>
                <option value="PERSONNOPENALTY">Person (No Penalty)</option>
                <option value="USBOARDINGSCHOOL">US Boarding School</option>
                <option value="CITY">
                    City
                    </option>
                <option value="COUNTRY">Country</option>
                <option value="COUNTRYBYPERSON">Country (By Person)</option>
                <option value="ISLAND">Island</option>
                <option value="BOOK">Book</option>
                <option value="FILM">Film</option>
                <option value="SONG">Song</option>
                <option value="PLANET">Planet</option>
                <option value="LANGUAGE">Language</option>
                <option value="HIGHSCHOOL">High School</option>
                <option value="CULINARYSCHOOL">Culinary School</option>
                <option value="SCHOOL">School</option>,
                <option value="SCHOOLBYPEOPLE">School (By Person)</option>,
                <option value="BUSINESS">Business</option>
                <option value="EMPLOYER">Employer</option>
                <option value="LISTARTICLE">List Article</option>
                <option value="THINKTANK">Think Tank</option>
                <option value="WORLDLEADER">World Leader</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="text">
            <Form.Label>Text</Form.Label>
            <Form.Control value={props.search.q} onChange={updateText} type="text" />
        </Form.Group>
        <Form.Group controlId="text">
            <Form.Label>Name</Form.Label>
            <Form.Control value={props.search.name} onChange={updateName} type="text" />
        </Form.Group>
        <Form.Group controlId="text">
            <Form.Label>Related WikidataIds</Form.Label>
            <Form.Control value={props.search.wikidataIds} onChange={updateWikidataIds} type="text" />
        </Form.Group>
                <Form.Group controlId="from">
                    <Form.Label>Birth Year</Form.Label>
                    <Form.Control value={props.search.birthYear} onChange={updateBirthYear} type="number" />
                </Form.Group>
        <Form.Group controlId="text">
            <Form.Label>Filter WikidataIds</Form.Label>
            <Form.Control value={props.search.filterWikidataIds} onChange={updateFilterWikidataIds} type="text" />
        </Form.Group>
        <Row>
            <Col>
                <Form.Group controlId="from">
                    <Form.Label>From</Form.Label>
                    <Form.Control value={props.search.startYear} onChange={updateStartYear} type="number" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="to">
                    <Form.Label>To</Form.Label>
                    <Form.Control value={props.search.endYear} onChange={updateEndYear} type="number" />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group controlId="everyYear">
                    <Form.Label>Every Year</Form.Label>
                    <Form.Control checked={props.search.everyYear} onChange={updateEveryYear} type="checkbox" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="leader">
                    <Form.Label>Leader Penalty</Form.Label>
                    <Form.Control checked={props.search.leaderPenalty} onChange={updateLeaderPenalty} type="checkbox" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="wikipedia">
                    <Form.Label>Wikipedia</Form.Label>
                    <Form.Control checked={props.search.wikipedia} onChange={updateWikipedia} type="checkbox" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="crossref">
                    <Form.Label>Crossref</Form.Label>
                    <Form.Control checked={props.search.crossref} onChange={updateCrossref} type="checkbox" />
                </Form.Group>
            </Col>
        </Row>
        <Form.Group controlId="limit">
            <Form.Label>Limit</Form.Label>
            <Form.Control value={props.search.limit} onChange={updateLimit} type="number" />
        </Form.Group>
    </>

}

export const BASE = {
    birthYear: -4000,
    startYear: -4000,
    endYear: 2022,
    kind: 'PERSON' as 'PERSON',
    wikipedia: true,
    crossref: false,
    leaderPenalty: false,
    q: '',
    wikidataIds: '',
    filterWikidataIds: '',
    limit: 100,
    journals: '',
    name: '',
    everyYear: false
}

