import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString, { parseUrl } from "query-string";
import * as React from "react";
import { useAppContext } from "./AppContext";
import { Query } from "./Query";
import Search from "./Search";
import Export from "./Export";
import Expand from "./Expand";
import Inspect from "./Inspect";
import Disciplines from "./Disciplines";
import WikipediaPage from "./WikipediaPage";
import Timeline from "./Timeline"
import Rank from "./Rank";
import Benchmark from "./Benchmark";
import CrossrefAuthorPage from "./CrossrefAuthorPage"
import { Nav, Navbar, Button, Tabs, Tab } from "react-bootstrap"
import { BASE } from "./SearchForm";
import { parse } from "path";

function extractQuery(): Query {
  const parsed = queryString.parse(window.location.hash);
  return {
    action: (parsed.action || "search") as Query["action"],
    q: parsed.q || "",
    title: parsed.title || "",
    target: parsed.target ? JSON.parse(parsed.target as string) : undefined,
    birthYear: parsed.birthYear ? parseInt(parsed.birthYear as string, 10) : -4000,
    startYear: parsed.startYear
      ? parseInt(parsed.startYear as string, 10)
      : -4000,
    endYear: parsed.endYear ? parseInt(parsed.endYear as string, 10) : 2022,
    kind: (parsed.kind as any) || "PERSON",
    given: (parsed.given as string) || "",
    family: (parsed.family as string) || "",
    wikipedia: parsed.wikipedia !== "false",
    crossref: parsed.crossref === "true",
    otherWeight: parseFloat(parsed.otherWeight as string || "1"),
    politicsWeight: parseFloat(parsed.politicsWeight as string || "1"),
    entertainmentWeight: parseFloat(parsed.entertainmentWeight as string || "1"),
    religionWeight: parseFloat(parsed.religionWeight as string || "1"),
    sportsWeight: parseFloat(parsed.sportsWeight as string || "1"),
    professionalWrestlingWeight: parseFloat(parsed.professionalWrestlingWeight as string || "1.0"),
    wikidataIds: (parsed.wikidataIds || ""),
    filterWikidataIds: (parsed.filterWikidataIds || ""),
    journals: (parsed.journals || ""),
    limit: parseInt(parsed.limit as string || "100", 10),
    link: parsed.link || "",
    name: parsed.name || ""
  } as Query;
}

function Main(props: { query: Query; updateQuery: (query: Query) => void }) {
  const { query, updateQuery } = props;
  switch (query.action) {
    case "search":
      return <Search query={query} updateQuery={updateQuery} />;
    case "inspect":
      return <Inspect query={query} updateQuery={updateQuery} />
    case "wikipedia-page":
      return <WikipediaPage query={query} updateQuery={updateQuery} />
    case "rank":
      return <Rank query={query} updateQuery={updateQuery} />
    case "disciplines":
      return <Disciplines updateQuery={updateQuery} />
    case "benchmark":
      return <Benchmark />
    case "timeline":
      return <Timeline query={query} updateQuery={updateQuery}/>
    case "crossref-author":
      return <CrossrefAuthorPage query={query} updateQuery={updateQuery} />
    case "export":
      return <Export query={query} updateQuery={updateQuery} />;
    case "expand":
      return <Expand query={query} updateQuery={updateQuery} />


  }
}

export default function App() {
  const { isSignedIn} = useAppContext();
  const [query, setQuery] = React.useState<Query>(extractQuery);

  const updateQuery = React.useCallback((query: Query) => {
    console.log("Q", query);
    window.location.hash = queryString.stringify({
      ...query,
      target: JSON.stringify((query as any).target),
    });
    setQuery(query)
  }, [setQuery]);

  const onHashChange = React.useCallback(() => {
    setQuery(extractQuery());
  }, [setQuery]);

  const onLogoClick = React.useCallback(() => {
    updateQuery({
      ...BASE,
      action: "search",
      q: "",
    });
  }, [updateQuery]);


  React.useEffect(() => {
    onHashChange();
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, [onHashChange]);

  return (
    <>
      <Navbar variant="dark" bg="dark">
        <Navbar.Brand onClick={onLogoClick}>RankingAnalytics</Navbar.Brand>
      </Navbar>
      <Nav variant="tabs" activeKey={query.action}>
        <Nav.Item>
          <Nav.Link eventKey="search" onClick={() => updateQuery({
            ...BASE,
            action: "search",
          })}>Search</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="timeline" onClick={() => updateQuery({
            ...BASE,
            action: "timeline",
          })}>Timeline</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="disciplines" onClick={() => updateQuery({
            action: "disciplines",
          })}>Disciplines</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="inspect" disabled>Inspect</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="wikipedia-page" onClick={() => updateQuery({
            action: "wikipedia-page",
            title: ''
          })}>Wikipedia Page</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="crossref-author" onClick={() => updateQuery({
            action: "crossref-author",
            title: ''
          })}>Crossref Author</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="rank" onClick={() => updateQuery({
            ...BASE,
            action: "rank"
          })}>Rank</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="rank" onClick={() => updateQuery({
            action: "benchmark"
          })}>Benchmark</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="expand" onClick={() => updateQuery({
            action: "expand",
            wikidataIds: ""
          })}>Expand</Nav.Link>
        </Nav.Item>
      </Nav>
      {isSignedIn && <Main query={query} updateQuery={updateQuery} />}
    </>
  );
}
