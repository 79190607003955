import {
  faCheck,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useApi } from "./AppContext";
import { Query, SearchQuery, Kind, SearchBase, TimelineQuery } from "./Query";
import { Container, Form, Button, Alert, Table, Row, Col } from "react-bootstrap";
import SiteButton from "./SiteButton"
import SearchForm, { prepareQuery } from "./SearchForm"
import { sortBy } from "lodash-es";

export default function Timeline(props: {
  query: TimelineQuery;
  updateQuery: (query: Query) => void;
}) {
  const [base, updateBase] = React.useState(props.query as SearchBase)

  const doSearch = React.useCallback(() => {
    props.updateQuery({
      ...base,
      action: "timeline",
    });
  }, [base, props.updateQuery]);

  const search = useApi(
    () => ({
      type: "TIMELINE",
      ...prepareQuery(base)
    }),
    [props.query]
  );

  const [currentYear, setCurrentYear] = React.useState(0)

  const onCurrentYearChange = React.useCallback((event) => {
    setCurrentYear(event.target.value)
  }, [setCurrentYear])

  const copyTimeline = React.useCallback(() => {
    navigator.clipboard.writeText('<script type="application/json" class="' + base.kind.toLowerCase() + '-timeline">' + JSON.stringify(search.result) + '</script>')
  }, [search.result, base.kind])

  return (
    <Container>

      <Form>
        <SearchForm search={base} updateSearch={updateBase} />
        <Button variant="primary" onClick={doSearch} >Build</Button>
      </Form>
      {search.result === undefined ? (
        <div>
          {search.statuses.map((status, index) => (
            <Alert variant="success" key={index}>
              <FontAwesomeIcon icon={faCheck} /> {status}
            </Alert>
          ))}
          {search.error === undefined && (
            <Alert variant="primary">
              <FontAwesomeIcon icon={faSpinner} spin /> Working
            </Alert>
          )}
        </div>
      ) : <div>
        <Button onClick={copyTimeline}>Copy</Button>
        <input style={{width: "100%"}} type="range" min={0} max={search.result.years.length} value={currentYear} onChange={onCurrentYearChange} step="1"/>
        <b>Year: </b>{search.result.years[currentYear]}<br/>

          <Table striped bordered>
            <tbody>
        {
          sortBy(search.result.items, item => -item.years[currentYear - item.skip]).slice(0, props.query.limit).map(item => 
              <tr>
                <td>
                  {item.name}
                </td>
                <td>
                  {item.years[currentYear - item.skip]}
                </td>
              </tr>
            )
        }

        </tbody>
            </Table>
      </div>
}
      {search.error && <Alert variant="error">{search.error}</Alert>}
    </Container>
  );
}
